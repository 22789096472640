<template>
<b-container fluid class="vh-100">
  <b-row align-v="center" class="h-100">
    <b-col cols="3" class="col-md-3" style="height: 97%">
      <zettelkasten-search v-on:lemma-selected="onLemmaSelected"></zettelkasten-search>
    </b-col>
    <b-col cols="9" style="height: 97%; border-left: 1px solid  darkgrey">
      <keep-alive>
        <zettelkasten-lemma v-if="lemmaID !== -1" v-bind:lemma-i-d="lemmaID" v-bind:entry-i-ds="entryIDs" v-bind:super-lemma="superLemma"></zettelkasten-lemma>
        <about v-else></about>
      </keep-alive>
    </b-col>
  </b-row>
</b-container>
</template>

<script>
import ZettelkastenSearch from '@/components/ZettelkastenSearch'
import ZettelkastenLemma from '@/components/ZettelkastenLemma'
import About from '@/components/About.vue'

export default {
  name: 'Zettelkasten',

  components: {
    ZettelkastenSearch,
    ZettelkastenLemma,
    About
  },

  data () {
    return {
      lemmaID: -1,
      entryIDs: [],
      superLemma: false
    }
  },

  methods: {
    onLemmaSelected: function (val) {
      this.lemmaID = val.lemma_id;
      this.entryIDs = val.entry_ids;
      this.superLemma = val.super;
    }
  }
}
</script>

<style>
.definition .akkadian {
  font-style: italic;
}
.definition .ES:before {
  content: "ES = ";
  color: gray;
}
.definition .UGN:before {
  content: "UGN = ";
  color: gray;
}
.definition .translation:before {
  content: "„";
}
.definition .translation:after {
  content: "“";
}
.definition .description {
  color: gray;
}
.definition .propernoun {
  color: gray;
}
</style>
