<template>
  <div class="overflow-auto h-100 w-100">
  <div style="max-width: 800px; margin-left: 100px;">
    <div class="title-page">
      <h1>Münchner<br>Sumerischer Zettelkasten</h1>
      <h3>betreut von Walther Sallaberger<br/>
        <span style="font-size: 80%">(<span v-html="emailWaltherSallaberger"></span>)</span></h3>
      <h3>unter Mitarbeit von:<br/>
        <span style="font-size: 80%">Alexa Bartelmus, Hagan Brunke, Sophie Cohen, Uta Einwaller, Marc Endesfelder, 
        Josephine Fechner, Tatiana Frühwirt, Brit Kärger, Ferdinand Kasten, Anne Löhnert, 
        Daniel Lopez, Kerstin Maiwald, Vera Meyer-Laurin, Sören Minx, Stefan Odzuck, 
        Alena Popoviczova, Doris Prechel, Felix Rauchhaus, Azam Rayat, Thèrése Rodin, 
        Walther Sallaberger, Hedwig Schmidt, Carsten Tardi, Annette Zgoll</span>
      </h3>
      <h3>
        und unter Benutzung älterer Sammlungen,
      </h3>
      <h3>
        digitale Fassung erstellt von Marc Endesfelder
      </h3>
    </div>
    <h2>Entstehungsgeschichte</h2>
    <p>
      Die Arbeit an dieser Sammlung von Sekundärliteratur zum Wortschatz des Sumerischen, zuvor "Leipziger Zettelkasten" (Fassung 1998) und "Leipzig-Münchner Zettelkasten" (Fassungen 2004, 2005, 2006) genannt, begann 1993 anlässlich der Neugründung des Altorientalischen Instituts der Universität Leipzig auf Initiative von Claus Wilcke. Die Sammlung an Sekundärliteratur sollte damals den Studierenden in Leipzig die Arbeit an sumerischen Texten erleichtern – inzwischen haben viele aus vielen Orten die Sammlung nützen können. Dabei stand Wilcke der Münchner Zettelkasten vor Augen, eine viele Tausende von Karteikarten umfassende Sammlung sumerischer Wörter mit Literaturhinweisen, und diesem realen Zettelkasten war augenzwinkernd der Titel des Projekts verpflichtet. Walther Sallaberger übernahm die Organisation der Sammlung und legte sie als Word-Datei an, die durch Tabulatoren gegliedert wurde, um eine spätere Überführung in eine Datenbank zu erleichtern. 1998 wurde eine erste Fassung des "Leipziger sumerischen Zettelkastens" zum Gebrauch verteilt.
    </p>
    <p>
      In den ersten Jahren konzentrierten wir uns auf die damals aktuellen Grundlagenwerke wie die Glossare zu den Königsinschriften FAOS 6 (1983) und 8 (1994), Attingers Elements (1993), Schretters Emesal-Studien (1991). Daran arbeiteten vor allem Vera Meyer-Laurin und Doris Prechel. Annette Zgoll steuerte zunächst aus München (1996-1998) und Leipzig (1999-2008), dann aus Göttingen (2008-2014) wesentliche Teile bei: sie exzerpierte zunächst selbst vor allem systematisch die Aufsätze von Åke W. Sjöberg sowie Lugale, studentischen Hilfskräften übertrug sie später die Indizes von lexikalisch wichtigen neu erschienen Monografien (etwa Mittermayer ELA, Civil ARES 4, Jaques Sentiments, Katz Netherworld, Meturan-Texteditionen).
    </p>
    <p> 
      Nach 1999 führte Walther Sallaberger die Edition und Koordination des Zettelkastens in München fort. Dort erstellten er und Hilfskräfte zahlreiche weitere Exzerpte von Aufsätzen und Monografien. Insbesondere durch die Arbeit von Hagan Brunke, gefördert von der Deutschen Forschungsgemeinschaft in den Vorarbeiten für ein Sumerisches Glossar (in Kooperation mit Pascal Attinger) 2004-06, wurde die Sammlung substanziell erweitert; die Exzerpte der Bände 4 bis 10 des Reallexikons für Assyriologie und Vorderasiatische Archäologie bilden den augenfälligsten Teil. Die vorläufig letzte Fassung wurde 2006 abgeschlossen und als PDF über die Homepage des Instituts für Assyriologie und Hethitologie der LMU allen Interessierten zur Verfügung gestellt.
    </p>
    <p>
      In der letzten, gleichzeitig leider auch der längsten Arbeitsphase, erfolgten einige Neuaufnahmen in bewährter Weise. Als weitere systematische Ergänzung exzerpierte Marc Endesfelder (2016-18) die Aufsätze und Rezensionen von Josef Bauer.
    </p> 
    <p> 
      Einen umfangreichen Zuwachs an Belegstellen verdanken wir der Abschrift der Karteikarten von Edzards Zettelkasten im Münchner Institut (2007-2016); daran arbeiteten vor allem Sophie Cohen, Marc Endesfelder, Stephan Odzuck, Alena Popoviczova, Felix Rauchhaus, Azam Rayat.
    </p> 
    <p>
      Der originale Münchner Zettelkasten war eine alphabetische Sammlung von Karteikarten zu sumerischen Wörtern, meist maschinenschriftlich, teils auch handschriftlich verfasst. Dietz Otto Edzard hatte diese Sammlung in seiner Zeit als Ordinarius für Assyriologie (1963-1999) angelegt; für ihn bildeten die Zettelkästen zum sumerischen und akkadischen Wortschatz in Heidelberg das Vorbild, doch wurde nach dem Beginn der akkadischen Wörterbücher (CAD 1956, AHw. 1959) in München nur das Sumerische verzettelt (C. Wilcke, 18.10.2020). Walter Farber schreibt dazu: "Die Zielsetzung war meiner Erinnerung nach einfach: Alle halbwegs seriöse assyriologische Literatur, die Indices besaß, irgendwann zu erfassen. Eigenverzettelungen von Texten oder Korpora waren dagegen nicht vorgesehen. Also eigentlich ein lexikalisch-bibliographisches Hilfsmittel" (Email vom 18.10.2020). Durch das Prinzip, nur Indizes – von der ZA und Monographien – aufzunehmen, boten die Karten in der Regel nichts als das sumerische Wort und die im Index gebotenen Belegstellen. Der Zettelkasten wurde nach Edzards Anweisungen von studentischen Hilfskräften vorangetrieben, W. Faber erinnert sich weiters, "1968-9 selbst hunderte oder gar tausende von Karten als HiWi geschrieben" zu haben (Email vom 18.10.2020), das Verfahren wurde auch in den 1990er Jahren so beibehalten (M. Krebernik, Email vom 18.10.2020). Der so im Laufe der Jahrzehnte gewachsene Zettelkasten stand als Hilfsmittel allen Studierenden und Gästen zur Verfügung, die in der Institutsbibliothek arbeiteten; sehr viele wissenschaftliche Arbeiten profitierten von dieser Sammlung.
    </p> 
    <p> 
      Auch wenn der Zettelkasten Edzards das Material unserer Sammlungen wesentlich vergrößerte, so sind hier durch das mehrfache Abschreiben (vom Text in den Index, dann in den Zettelkasten, dann in unsere Wortlisten) und die Reduktion der Daten auf die reine Belegstelle Fehler besonders häufig. Der kurz skizzierte Prozess hat auch zur Folge, dass es immer wieder zu Duplizierungen gekommen ist. So konnte es passieren, dass ein Zitat aus einem Artikel von Å.W. Sjöberg zum Beispiel aus dem ZA-Index auf eine Karte in Edzards Zettelkasten übertragen wurde, zusätzlich aus dem AfO-Register dorthin gelangte sowie unter den Exzerpten der Sjöberg-Aufsätze durch Annette Zgoll erfasst wurde.
    </p> 
    <p>
      Die vorliegende Sammlung hatte in Leipzig als Ersatz für den originalen, hier wieder aufnommenen Münchner Zettelkasten begonnen, Annette Zgoll steuerte aus München, Leipzig und Göttingen Daten bei, seit über 20 Jahren wird er in München betreut. Vor diesem Hintergrund erfolgte die Umbenennung in "Münchner sumerischer Zettelkasten".
    </p>
    <p>
      Nach der letzten Fassung von 2006, die vor allem Hagan Brunke redigierte, ist der Abschluss der Fassung vom 20.10.2020 zuerst dem Einsatz von Felix Rauchhaus zu verdanken, der die umfangreichen Dateien mit verschiedenen Sammlungen zusammenführte. Das Format eines Wörterbuchs bietet den unschätzbaren Vorteil, dass man leicht vorangehende und nachfolgende Einträge erfassen kann und so oft wichtige Hinweise an nicht erwarteter Stelle findet. Die Sammlung wurde aber zu umfangreich, um in dieser Form weitergeführt zu werden. Von Anfang an war auch die Überführung in eine Datenbank konzipiert.
    </p>
    <p>
      Es freut mich deshalb sehr, dass Marc Endesfelder im Rahmen eines laufenden DFG-Projekts zur sumerischen Orthographie diese digitale Datenbank-Version erstellt hat, die eine vielfältige und rasche Suche ermöglicht. Tatiana Frühwirt half beim Ausmerzen formaler Fehler (2020-21). Die 1963 in München und 1993 in Leipzig begonnenen und seit 1999 in München fortgeführten Sammlungen zur Sekundärliteratur werden damit in neuer Weise zugänglich gemacht.
    </p>
        
    <h2 class="text-h4">Mitarbeiter in Leipzig, Göttingen und München</h2>
    <p>
      In alphabetischer Reihenfolge arbeiteten folgende Personen am Zettelkasten mit (Gö = Göttingen, L = Leipzig, M = München):
    </p>
    <p>
      <table>
        <tr><td>AB</td><td>Alexa Bartelmus (2005-06) (M)</td></tr>
        <tr><td>HB</td><td>Hagan Brunke (2004-09) (M)</td></tr>
        <tr><td>SC</td><td>Sophie Cohen (2011-14) (M)</td></tr>
        <tr><td>UE</td><td>Uta Einwaller (2001-02) (M)</td></tr>
        <tr><td>ME</td><td>Marc Endesfelder (geb. Elsässer) (2014-16, 2018-20) (M)</td></tr>
        <tr><td>JF</td><td>Josephine Fechner (2010) (L)</td></tr>
        <tr><td>TF</td><td>Tatiana Frühwirt (2020) (M)</td></tr>
        <tr><td>BJ</td><td>Brit Kärger (geb. Jahn)(2004-08) (L)</td></tr>
        <tr><td>FK</td><td>Ferdinand Kasten (2002) (L)</td></tr>
        <tr><td>AL</td><td>Anne Löhnert (2002-04) (M)</td></tr>
        <tr><td>DL</td><td>Daniel Lopez (2009) (M)</td></tr>
        <tr><td>KM</td><td>Kerstin Maiwald (2014) (Gö)</td></tr>
        <tr><td>VML</td><td>Vera Meyer-Laurin (1994-95) (L)</td></tr>
        <tr><td>SM</td><td>Sören Minx (2004) (L)</td></tr>
        <tr><td>SO</td><td>Stefan Odzuck (2008-09) (M)</td></tr>
        <tr><td>AP</td><td>Alena Popoviczova (2007-09) (M)</td></tr>
        <tr><td>DP</td><td>Doris Prechel (1994-96) (L)</td></tr>
        <tr><td>FR</td><td>Felix Rauchhaus (geb. Seifert) (2018-20) (M)</td></tr>
        <tr><td>AR</td><td>Azam Rayat (2014-16) (M)</td></tr>
        <tr><td>TR</td><td>Thèrése Rodin (2000) (L)</td></tr>
        <tr><td>WS</td><td>Walther Sallaberger (1993-2020) (M)</td></tr>
        <tr><td>HS</td><td>Hedwig Schmidt (1994-99) (L)</td></tr>
        <tr><td>CT</td><td>Carsten Tardi (2005-10) (Gö)</td></tr>
        <tr><td>AZ</td><td>Annette Zgoll (geb. Ganter) (1994-96, 1999-2014) (L, Gö)</td></tr>
      </table>
    </p>
    <p>
      Die jeweils genannten Bearbeiter erstellten die Liste von Lemmata zur Einarbeitung in die Gesamtliste, die von Walther Sallaberger betreut wurde.<br/> 
      Der sumerische Zettelkasten erschien in mehreren Fassungen:
    </p>
    <p>
      Der sumerische Zettelkasten erschien in mehreren Fassungen: 
      <ul>
        <li>
          Fassung 1998 (Leipzig, erarbeitet von Walther Sallaberger)
        </li>
        <li>
          Fassung vom 4. 3. 2004 (Edition mit Einarbeiten neuer Daten: Anne Löhnert)
        </li>
        <li>
          Fassung vom 4. 3. 2005 und vom 26. 9. 2006 mit 710 Seiten in 11pt (ohne Vorwort) (Edition mit Einarbeiten neuer Daten: Hagan Brunke)
        </li>
        <li>
          <a href="msz_2020.pdf">Fassung vom 22.10.2020</a>, mit 1113 Seiten in 10pt (Edition mit Einarbeiten neuer Daten: Felix Rauchhaus)
        </li>
        <li>
          die vorliegende digitale Fassung der Datenbank erstellt von Marc Endesfelder beruhend auf der Fassung vom 22.10.2020 (mit vor allem formalen Korrekturen)
        </li>
      </ul>
    </p>
    <p>
      Allen genannten Personen sei herzlichst gedankt für ihre Arbeit zum sumerischen Wortschatz, die zu der vorliegenden Sammlung geführt hat. Ohne den persönlichen Einsatz so vieler, die Sammlung an Sekundärliteratur stets zu erweitern und ihren Aufbau zu verbessern, hätten wir dieses Ergebnis nicht erreichen können.
    </p>

    <h2 class="text-h4">Wichtige Bausteine des "Zettelkastens"</h2>
    Wie aus der gebotenen Entstehungsgeschichte abzulesen, umfasst der "Münchner sumerische Zettelkasten" also in chronologischer Reihenfolge folgende größeren Elemente:
    <ul>
        <li>
          Dietz Otto Edzards Zettelkasten von 1963-1999, der als bibliographisches Hilfsmittel die Indizes der wichtigsten sumerologischen Neuerscheinungen sowie die Indizes der ZA und von AfO umfasste [übertragen in die vorliegende Liste 2007-2016 von SC, DL, ME, SO, AP, FR, AR]
        </li>
        <li>
          Grundlagenwerke: Indizes und Exzerpte (FAOS 6, 8, Attinger, Schretter, Thomsen) [in Leipzig und München DP, VML; UE/CT]
        </li>
        <li>
          Indizes weiterer sumerologischer Monografien bis etwa 2015 [alle] 
        </li>
        <li>
          Exzerpte von Literatur [HB, CT, WS], meist keine systematischen Sammlungen abgesehen von Reallexikon für Assyriologie Band 4-10 [HB]
        </li>
        <li>
          Exzerpte der Aufsätze von Åke W. Sjöberg [AZ]
        </li>
        <li>
          Exzerpte der Aufsätze und Rezensionen von Josef Bauer [ME]
        </li>
    </ul>

    <h2 class="text-h4">Die wichtigsten Exzerpte und Indizes</h2>
    <p>
      Abgesehen von Edzards Münchner Zettelkasten und vielen einzelnen Exzerpten von Aufsätzen und Beiträgen sind die folgenden:
    </p>
    <div class="block">
      Archi et al., Torino 3 = Archi/Pomponio/Stol, Testi cuneiformi di vario contenuto. N: 0724-0793 (Catalogo del Museo Egizio di Torino IX) Torino 1999 [WS]<br/>
    </div>
    <div class="block">
      Attinger = Attinger,  A.,  Eléments  de  linguistique  sumérienne.  La  construction  de  du11/e/di  "dire",  Göttingen  1993. [VML]<br/>
    </div>
    <div class="block">
      Attinger, N.A.B.U. 2004 Nr. 3, S. 79-81 „Les ’verbes composés’ en sumérien“ [CT]<br/>
    </div>
    <div class="block">
      Averbeck = Averbeck, R.E., A preliminary study of ritual and structure in the Cylinders of Gudea, Ann Arbor 1987. [VML]<br/>
    </div>
    <div class="block">
      Bauer, J. Schriften bis 2015 [ME]<br/>
    </div>
    <div class="block">
      BFE = Krebernik, M., Die Beschwörungen aus Fara und Ebla, Hildesheim 1984. [DP]<br/>
    </div>
    <div class="block">
      Brunke, EiS =  Brunke,  H.,  Essen  in  Sumer:  Metrologie,  Herstellung  und  Terminologie  nach  Zeugnis  der  Ur  III-zeitlichen Wirtschaftsurkunden, München 2011 [HB]<br/>
    </div>
    <div class="block">
      Cavigneaux ZA 83 (1993) und 85 (1995) = Cavigneaux / Al-Rawi, Textes magiques de Tell Haddad I-III, ZA 83 (1993) 170-205; ZA 85 (1995) 19-46. 169-220 [CT]<br/>
    </div>
    <div class="block">
      Cavigneaux, CM 19 (1992) = Cavigneaux / Al-Rawi, „Gilgames et la mort ; Textes de Tell Haddad VI“, CM 19 (1992). [CT]<br/>
    </div>
    <div class="block">
      Cavigneaux, Iraq 55 =  A. Cavigneaux/F. Al-Rawi, Iraq 55 (1993), 91-105: New Sumerian Literary Texts from Tell Haddad [WS]<br/>
    </div>
    <div class="block">
      Charpin, Clergé = D. Charpin, Le clergé d'Ur au siècle d'Hammurabi, Genève-Paris 1986. [DP]<br/>
    </div>
    <div class="block">
      Civil, ARES 4 = Civil, Miguel: The Early Dynastic Practical Vocabulary A (Archaic HAR-ra). ARES 4, 2008 [NN Göttingen]<br/>
    </div>
    <div class="block">
      Civil, Farmer’s Instructions = M. Civil, The Farmer’s Instructions. AuOr. 5 [CT]<br/>
    </div>
    <div class="block">
      CM 7 = I. L. Finkel/M.J. Geller (eds.), Sumerian Gods and their Representations, Groningen 1997 (in der Liste zitiert nach Autor, z.B. Edzard, CM 7) [HS]<br/>
    </div>
    <div class="block">
      van Dijk, Lugale = J.J.A. van Dijk, <i>Lugal</i> I (1983): bis Z.603 mit Rezension von M.J. Geller, BSOAS 48 (1985) bis Z.541. Ab Z.579 sind die jüngere und die akkadische Version nicht mehr verzettelt. [AZ]<br/>
    </div>
    <div class="block">
      FAOS 6 = Behrens, H./Steible, H., Glossar zu den altsumerischen Bau- und Weihinschriften, Wiesbaden 1983. [DP]<br/>
    </div>
    <div class="block">
      FAOS 8 = Kienast, B., Glossar zu den altakkadischen Königsinschriften, Stuttgart 1994. [DP]<br/>
    </div>
    <div class="block">
      FAOS 15,1 = Selz, G.J., Die altsumerischen Wirtschaftsurkunden der Emeritage zu Leningrad, Stuttgart 1989. [HB]<br/>
    </div>
    <div class="block">
      FAOS 15,2 = Selz, G.J., Altsumerische Wirtschaftsurkunden aus amerikanischen Sammlungen, Stuttgart 1993. [HB]<br/>
    </div>
    <div class="block">
      FAOS 19 = Kienast, B./Volk, K., Die sumerischen und akkadischen Briefe, Stuttgart 1995 [UE, WS, HB]<br/>
    </div>
    <div class="block">
      Fara Tablets = HP  Martin/F  Pomponio/G  Visicato/A  Westenholz, The Fara Tablets in the University of Pennsylvania Museum of Archaeology and Anthropology, 2001 [WS]<br/>
    </div>
    <div class="block">
      Ferwerda, SLB 5 = G. Th. Ferwerda, A Contribution to the Early Isin Craft Archive, Leiden 1985 [DP]<br/>
    </div>
    <div class="block">
      Flückiger, Urnamma = Flückiger-Hawker, E., Urnamma of Ur in Sumerian Literary Tradition, Freiburg/Schweiz 1999 [UE]<br/>
    </div>
    <div class="block">
      Foster, USP = Foster, B. R., Umma in the Sargonic Period, Yale 1975 [WS]<br/>
    </div>
    <div class="block">
      Fs Birot = Miscellanea babylonica. Mélanges offerts à Maurice Birot, Paris 1985. [DP]<br/>
    </div>
    <div class="block">
      HSAO 2 = H. Waetzoldt/H. Hauptmann (Hrsg.), Wirtschaft und Gesellschaft von Ebla [=Heidelberger  Studien  zum Alten Orient 2], Heidelberg 1988. [DP]<br/>
    </div>
    <div class="block">
      InEb. (Attinger, ZA 88, 164-95) = P. Attinger, ZA 88 (1998), 164-95: Inanna et Ebih [UE]<br/>
    </div>
    <div class="block">
      Jaques Sentiments = Jaques, M. Le vocabulaire des sentiments dans les textes sumériens. AOAT 332 (2006) [JF]<br/>
    </div>
    <div class="block">
      Katz, Netherworld = D. Katz, The Image of the <i>Nether World</i> in the Sumerian Sources (2003)[NN Göttingen]<br/>
    </div>
    <div class="block">
      MARI 1-7 (Register) [DP]<br/>
    </div>
    <div class="block">
      MEE 10 = P. Mander, Administrative Texts of the Archive L. 2769, Rom 1990. [DP]<br/>
    </div>
    <div class="block">
      Michalowski, Ur-Klage, MC 1 [SM]<br/>
    </div>
    <div class="block">
      Mittermayer, ELA = C. Mittermayer, Enmerkara und der Herr von Arata. Ein ungleicher Wettstreit (= Orbis Biblicus et Orientalis 239), Fribourg/Göttingen 2009. [KM]<br/>
    </div>
    <div class="block">
      RlA 4-10 = Reallexikon der Assyriologie und Vorderasiatischen Archäologie, 4-10/7-8. Berlin 1972-205 [HB]<br/>
    </div>
    <div class="block">
      Robson, OECT 14 = E. Robson, Mesopotamian Mathematics 2100-1600 BC. Oxford 2000 [HB]<br/>
    </div>
    <div class="block">
      Schretter = Schretter,  M.K., Emesal-Studien. Sprach- und literaturgeschichtliche Untersuchungen zur sogenannten Frauensprache des Sumerischen, Innsbruck 1990. [DP]<br/>
    </div>
    <div class="block">
      Sjöberg, Å.W. Aufsätze 1962-1988 [AZ]<br/>
    </div>
    <div class="block">
      Pomponio &amp; Visicato, Šuruppak = Pomponio, F./Visicato, G., Early Dynastic Administrative Tablets of Šuruppak, Neapel 1994. [WS]<br/>
    </div>
    <div class="block">
      Thomsen, Mes. 10 = M.-L. Thomsen, The Sumerian Language, Kopenhagen 1984 [UE, CT]<br/>
    </div>
    <div class="block">
      Sallaberger, UAVA 7 = Sallaberger, W., Der kultische Kalender der Ur III-Zeit, Berlin 1993 [WS, HS]<br/>
    </div>
    <div class="block">
      Volk, InŠuk. = Volk, K., Inanna und Šukaletuda, Zur historisch-politischen Deutung eines sumerischen Literaturwerkes (Santag 3), Wiesbaden 1995 [HS, UE]<br/>
    </div>
    <div class="block">
      Zgoll, Nin-me-šara = Zgoll, A., Der Rechtsfall der En-hedu-Ana im Lied nin-me-šara [UE]<br/>
    </div>
    <p></p>
    <p>
      Die allgemeinen und bibliographischen Abkürzungen im Zettelkasten werden hier nicht einzeln aufgeschlüsselt.
    </p>
    
    <h2 class="text-h4">Konzept des "Zettelkastens"</h2>
    <p>
      Das Ziel des Sumerischen Zettelkastens blieb seit dem Beginn in Leipzig 1993 unverändert dasselbe, nämlich einen ersten Hinweis zu wichtiger Sekundärliteratur zu sumerischen Wörtern zu geben. Die ausgewählte Sekundärliteratur sollte möglichst alle Perioden und Genres abdecken; ein Schwerpunkt lag auf neu erschienen, lexikographisch weiterführenden Arbeiten. Die Sammlung umfasst nicht nur Lexeme der sumerischen Sprache, sondern vereinzelt auch Logogramme und Zeichen(namen). Anstelle nur die Lesung des Originalwerks und die Belegstelle zu bieten, werden hier folgende Prinzipien befolgt:
      <ol>
        <li>
          Es wird eine Dokumentation der Forschung zum sumerischen Lexikon anhand von Sekundärliteratur angestrebt.
        </li>
        <li>
          Alle Einträge eines Lemmas werden an einer einzigen Stelle angeordnet, auch wenn die jeweiligen Autoren andere Lesungen bieten oder es sich um unorthographische Schreibungen handelt. Die Lesung des Lemmas muss aber nicht korrekt sein. So findet man also Belege aus der Sekundärliteratur, die ĝeš schreiben, unter dem Lemma ĝiš. Dieses systematische Erfassen verschiedener Lesungen unter einem Lemma erleichterte die Überführung in die vorliegende Datenbank. Zudem lassen sich in dieser Form vollständig die Belege für ein Wort oder eine Silbenfolge an nicht-erster Stelle auffinden.
        </li>
        <li>
          Bei den Exzerpten wird kurz erläutert, was an der jeweiligen Stelle zu finden ist (so wie sonst nur bei den AfO-Registern). So kann schon ein Blick in die Sammlung einer ersten Orientierung dienen, bevor man die Zitate nachschlägt. Die Auswahl der Sekundärliteratur bietet zudem Lesern, die die zitierten Werke kennen, oft schon einen Eindruck der Verteilung eines Lemmas im sumerischen Schrifttum.
        </li>
      </ol>
      Das Konzept konnte aber nur unzureichend umgesetzt werden; dazu sind unten die Warnhinweise zu beachten.
    </p>

    <h2 class="text-h4">Aufbau der Einträge im "Zettelkasten"</h2>
    <p>
      <b>Lemma</b> (= Haupteintrag), ggf. mit Varianten; "deutsche Übersetzung"; <i>akkadische Gleichung</i><br/>
      Literaturzitat abgekürzt; es folgt bei Exzerpten in (...) eine Angabe, was an der Stelle behandelt wird; selten sind in [...] Kommentare eines Bearbeiters.
    </p>
    <p>
      Ist das Wort an der angegebenen Stelle in derselben Form zu finden, wie der Haupteintrag lautet, steht nur das Literaturzitat. Bei Abweichungen (andere oder falsche Lesung, Schreibungsvariante) wird diejenige Form dem Literaturzitat vorangestellt, die sich an der angegebenen Stelle findet.
    </p>
    
    <h2 class="text-h4" style="color: red">Wichtiger Warnhinweis!</h2>
    <p>
      Zur Vermeidung weiterer Schäden in der Sumerologie sei mit größtem Nachdruck vor den Unzulänglichkeiten des "Münchner sumerischen Zettelkastens" gewarnt:
      <ul>
        <li>
          Trotz der Konzentration auf Sekundärliteratur wurden vor allem über die erfassten Indizes auch Wortbelege in Texteditionen erfasst, was die Beleglisten teils unnötig aufbläht (ein- und dieselbe Textstelle ist oft mehrmals erfasst).
        </li>
        <li>
          Beim Ansatz der Lemmata wurde weder auf korrekte Lesungen umgestellt noch völlige Konsistenz innerhalb des Zettelkastens erreicht, manchmal nicht einmal angestrebt.
        </li>
        <li>
          Der Ansatz von Lemmata ist inkonsistent; ob eine Phrase unter dem Leitwort oder als ein eigenes Lemma angeführt wird, lässt sich kaum vorhersagen.
        </li> 
        <li>
          Die Querverweise und Vereinheitlichungen sind unvollständig, so dass dasselbe Wort unter verschiedenen Lesungen an verschiedenen Stellen erscheinen kann.
        </li> 
        <li>
          Die deutsche Übersetzung des Lemmas sowie die akkadischen Gleichungen dienen nur der ersten Orientierung, bieten aber kein Ergebnis lexikalischer Studien.
        </li> 
        <li>
          Das Erfassen von Sekundärliteratur ist im Umfang äußerst beschränkt und der Zettelkasten bietet deshalb nur einen zufällig ausgewählten Ausschnitt der Arbeiten zum sumerischen Wortschatz.
        </li> 
        <li>
          Durch die Übernahme verschiedener Sammlungen (alter Zettelkasten, Indizes, neue Exzerpte) und Abschreiben entstanden nicht nur (unschädliche) Doppelungen, sondern auch sehr viele Fehler jeder Art.
        </li> 
        <li>
          Ein systematisches Korrektur-Lesen fand nicht statt.
        </li> 
      </ul>
    </p>
    <p>
      Der Benutzer muss sich also stets bewusst sein, dass der "Münchner sumerische Zettelkasten" nur einen <b>zufälligen Ausschnitt</b> der Sumerologie dokumentiert und dabei <b>notorisch unzuverlässig, inkonsistent und fehlerhaft</b> ist. Doch auch unter diesen Voraussetzungen hat sich der Zettelkasten oft schon als äußerst nützliches Instrument erwiesen, um sich im Labyrinth der Sekundärliteratur zum sumerischen Wortschatz zurechtzufinden.
    </p>
    <p>
      Vor diesem Hintergrund sind jegliche Korrekturhinweise selbstverständlich höchst willkommen und können direkt an mich (<span v-html="emailWaltherSallaberger"></span>) geschickt werden.
    </p>

    <p>
      München, den 12.03.2021<br/>
      Walther Sallaberger
    </p>
  </div>
  </div>
</template>

<script>
export default {
  name: "About",

  components: {},

  data() {
    return {
      emailWaltherSallaberger:
        '<a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#119;&#97;&#115;&#97;&#64;&#108;&#109;&#117;&#46;&#100;&#101;" title="Send email to: &#119;&#97;&#115;&#97;&#64;&#108;&#109;&#117;&#46;&#100;&#101;">&#119;&#97;&#115;&#97;&#64;&#108;&#109;&#117;&#46;&#100;&#101;</a>',
    };
  },
};
</script>

<style>
div.block {
  text-indent: -2em;
  margin-left: 2em;
}
h1 {
  font-size: 320%;
  text-align: center;
}
h2 {
  font-size: 180%;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-align: center;
}
h3 {
  font-size: 120%;
  text-align: center;
}
.title-page {
  margin-top: 3em;
  margin-bottom: 3em;
}
</style>