<template>
  <b-container class="d-flex flex-column h-100">
    <b-row>
      <b-col>
        <b-input-group>
          <b-form-input
            type="search"
            placeholder="Suche…"
            @change="onSearch"
          ></b-form-input>
          <b-input-group-append>
            <b-button class="options-button" id="popover-target-1">
              <b-icon icon="three-dots-vertical"></b-icon>
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-popover
          target="popover-target-1"
          triggers="click blur"
          placement="bottomright"
        >
          <strong>Kurzhilfe</strong>
          <ul style="padding-left: inherit">
          <li>
            <code>š</code>, <code>ĝ</code> und <code>ř</code> für <code>c</code>, <code>j</code> und <code>v</code> (optional)
          </li>
          <li>
            Großbuchstaben für <b>Zeichen</b> unabhängig ihrer Lesung: <code>BI2</code>
          </li>
          <li>
            Schrägstriche für <b>Index-unabhängiges</b> Suchen: <code>/bi/</code> für bi, <span class="cuneiform sumerian">bi<span class="cun_index">2</span></span>, etc. 
          </li>
          <li>
            Geschweifte und spitze Klammern für <b>Determinative</b> und <b>phonetische Komplemente</b>: <code>{ĝeš}</code>, <code>&lt;a&gt;</code>
          </li>
          <li>
            <code>X</code> für ein <b>beliebes Zeichen</b>
          </li>
          <li>
            Bindestrich für Zeichen im <b>selben Wort</b>: <code>mu-DU</code>.
          </li>
          </ul>
          <strong>Suchoptionen</strong>
          <b-table-simple borderless small>
            <b-thead>
              <b-td>Suche nach…</b-td>
              <b-td>Kosten</b-td>
            </b-thead>
            <b-tbody>
              <b-tr v-for="score in scores" :key="score.id">
                <b-td>
                  <b-checkbox v-model="score.active">{{
                    score.desc
                  }}</b-checkbox>
                </b-td>
                <b-td>
                  <b-spinbutton
                    v-model="score.score"
                    min="0"
                    size="sm"
                  ></b-spinbutton>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-popover>
      </b-col>
    </b-row>
    <b-row class="flex-grow-1 overflow-auto">
      <b-col>
        <div
          class="d-flex justify-content-center align-items-center h-50"
          v-if="loading"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <b-table-simple hover>
          <b-tbody>
            <b-tr
              v-for="row in filteredRows"
              :key="row['row_number']"
              v-on:click="onSelectResult(row)"
              v-bind:class="{ 'table-active': row['row_number'] == active_row }"
            >
              <b-td>
                <span class="lemma-name" v-html="row['expression']"></span>&ensp;
                <span class="definition" v-html="row['definition']"></span>
                <div
                  v-for="result in row['results']"
                  v-bind:key="result['row_number']"
                >
                  &emsp;
                  <span v-html="result['expression']"></span>
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      rows: [],
      active_row: 0,
      scores: [
        {
          attr: "lemma",
          desc: "einzelnen Einträgen",
          active: true,
          score: 1,
          id: 0,
        },
        {
          attr: "complete_word",
          desc: "Teilwörtern",
          active: true,
          score: 2,
          id: 1,
        },
        {
          attr: "complete",
          desc: "Teiltreffern",
          active: true,
          score: 3,
          id: 2,
        },
        {
          attr: "direct",
          desc: "alternativen Lesungen",
          score: 4,
          active: true,
          id: 3,
        },
      ],
      loading: false,
    };
  },

  components: {},

  methods: {
    onSearch: function (val) {
      this.rows = [];
      this.loading = true;
      if (val) {
        axios
          .post(
            "/zettelkastensearch",
            {
              searchString: val,
            },
            {
              responseType: "json",
            }
          )
          .then((response) => {
            this.loading = false;
            this.rows = response.data.rows;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    onSelectResult: function (row) {
      if (this.active_row !== row["row_number"]) {
        const entry_ids = row.results.map((x) => x.entry_id);
        this.$emit("lemma-selected", {
          lemma_id: row["lemma_id"],
          entry_ids: entry_ids,
          super: row["super"],
        });
        this.active_row = row["row_number"];
      } else {
        this.$emit("lemma-selected", {
          lemma_id: -1,
          entry_ids: [],
          super: false,
        });
        this.active_row = -1;
      }
    },
  },

  computed: {
    filteredRows: function () {
      let r = [];
      for (let lemma of this.rows) {
        let lemma_new = JSON.parse(JSON.stringify(lemma));
        lemma_new.results = lemma.results.filter((x) =>
          this.scores.reduce(
            (sum, val) => sum && (x[val.attr] || val.active),
            true
          )
        );
        lemma_new.results = lemma_new.results.map((x) =>
          Object.assign(x, {
            score: this.scores.reduce(
              (sum, val) => sum + x[val.attr] * Math.pow(val.score, 2),
              0
            ),
          })
        );
        lemma_new.results.sort((a, b) =>
          a.entry_id === b.entry_id
            ? a.score < b.score
            : a.entry_id > b.entry_id
        );
        lemma_new.results = lemma_new.results.filter(
          (x, i, a) => i === 0 || x.entry_id !== a[i - 1].entry_id
        );
        lemma_new.score = lemma.results.reduce((max, value) => {
          return Math.max(max, value.score);
        }, 0);

        if (!lemma_new.results.length) continue;

        if (
          lemma_new.results[0].entry_id == -1 &&
          lemma_new.results[0].score == lemma_new.score
        )
          lemma_new.results = [];
        else
          lemma_new.results = lemma_new.results.filter((x) => x.entry_id != -1);
        r.push(lemma_new);
      }
      r.sort((a, b) => a.score < b.score);
      return r;
    },
  },
};
</script>

<style>
.options-button {
  padding-right: 0;
  padding-left: 0;
}
</style>