<template>
  <b-container fluid class="h-100 overflow-auto lemma">
    <section v-for="lemma in lemmas" :key="lemma['key']">
      <component :is="superLemma && lemma['key'] ? 'h2' : 'h1'"
        ><span class="lemma-name" v-html="lemma.lemma['expression']"></span
        >&emsp;<span
          class="definition"
          v-html="lemma.lemma['definition']"
        ></span
      ></component>
      <b-table-simple fixed>
        <b-tbody>
          <b-tr
            v-for="entry in lemma['entries']"
            :key="entry['row_number']"
            :variant="
              entryIDs.indexOf(entry['entry_id']) !== -1 ? 'secondary' : ''
            "
          >
            <b-td v-html="entry['expression']"></b-td>
            <b-td v-html="entry['citation']"></b-td>
            <b-td :colspan="2" v-html="entry['description']"></b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </section>
  </b-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      lemmas: [],
      lemmaIDs: [],
    };
  },

  props: {
    lemmaID: Number,
    entryIDs: Array,
    superLemma: Boolean,
  },

  methods: {
    loadLemmaIDs: function (val) {
      this.lemmaIDs = [val];
      if (this.superLemma) {
        axios
          .post("/zettelkastensuperlemma", { superlemma_id: val }, {
            responseType: "json",
          })
          .then((response) => {
            this.lemmaIDs = [val].concat(
              response.data.rows.map((x) => x.lemma_id)
            );
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  },

  watch: {
    lemmaID: function (val) {
      this.loadLemmaIDs(val);
    },

    lemmaIDs: function (val) {
      let a = [];
      for (let i = 0; i < val.length; ++i) {
        a[i] = { key: i, lemma: {}, entries: [] };
      }
      this.lemmas = a;
      for (let i = 0; i < val.length; ++i) {
        axios
          .post("/zettelkastenlemma", { lemma_id: val[i] }, {
            responseType: "json",
          })
          .then((response) => {
            this.lemmas[i].lemma = response.data.rows[0];
          })
          .catch((e) => {
            console.log(e);
          });
        axios
          .post("/zettelkastenentries", { lemma_id: val[i] }, {
            responseType: "json",
          })
          .then((response) => {
            this.lemmas[i].entries = response.data.rows;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },

  mounted() {
      this.loadLemmaIDs(this.lemmaID);
    }
};
</script>

<style>
.lemma h1 {
  font-size: 1.5em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  text-align: left;
}

.lemma h2 {
  font-size: 1.3em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  text-align: left;
}

.cun_sign {
  text-transform: lowercase;
  font-variant: small-caps;
}
.cun_det {
  vertical-align: super;
  font-size: smaller;
}
.cun_pc {
  vertical-align: super;
  font-size: smaller;
  font-style: italic;
}
.cun_r_con {
  vertical-align: super;
  font-size: smaller;
}
.cun_index {
  vertical-align: sub;
  font-size: smaller;
}
.cun_desc {
  font-style: italic;
  color: gray;
}
.italic {
  font-style: italic;
}
.sub {
  vertical-align: sub;
  font-size: smaller;
}
.super {
  vertical-align: super;
  font-size: smaller;
}
.lemma-name {
  font-weight: bold;
}
</style>
