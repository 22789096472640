<template>
  <div id="app">
    <zettelkasten/>
  </div>
</template>

<script>
import Zettelkasten from '@/views/Zettelkasten'

export default {
  name: 'App',
  components: {
    Zettelkasten
  }
}
</script>

<style>
@font-face {
  font-family: "DejaVu Sans";
  src: url("./assets/dejavusans-webfont.woff2") format("woff2"),
    url("./assets/dejavusans-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  unicode-range: U+0000-02FF, U+1E00-1EFF, U+2E22-2E23;
}
@font-face {
  font-family: "DejaVu Sans";
  src: url("./assets/dejavusans-bold-webfont.woff2") format("woff2"),
    url("./assets/dejavusans-bold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  unicode-range: U+0000-02FF, U+1E00-1EFF, U+2E22-2E23;
}
@font-face {
  font-family: "DejaVu Sans";
  src: url("./assets/dejavusans-oblique-webfont.woff2") format("woff2"),
    url("./assets/dejavusans-oblique-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  unicode-range: U+0000-02FF, U+1E00-1EFF, U+2E22-2E23;
}
@font-face {
  font-family: "DejaVu Sans";
  src: url("./assets/dejavusans-boldoblique-webfont.woff2") format("woff2"),
    url("./assets/dejavusans-boldoblique-webfont.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  unicode-range: U+0000-02FF, U+1E00-1EFF, U+2E22-2E23;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #333232;
  font-family: 'DejaVu Sans';
}

</style>
